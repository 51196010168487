export default {
  communication: {
    enterPinCode: "One more step! Please enter the 6-digit code sent to your email address.",
    oneTimeDownloadLink: "This is a one-time download link. If you encounter any issues or have questions, please contact our team."
  },
  messages: {
    downloadStarted: "Download has just started!",
    okGotIt: "Ok, got it!"
  },
  learnMore: "Learn More",
  download: "Download",
  fileName: "File name",
  send: "Send",
  twoFASecurity: "Identity Verification",
  validation: {
    fieldRequired: "This field is required"
  },
  docReady: "Your Document is Ready!",
  date: "Date",
  actions: "Actions"
}
