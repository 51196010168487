import axiosInstance from './api'
import TokenService from './token.service'
import snackbarPlugin from '@/plugins/snackbar'
import router from '../router'

const setup = (store) => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response
    },
    async(error) => {
      if (error.request.status === 404) {
        if (!error.response.data.detail.includes('The asset does not exist')) {
          router.push('/')
        }
      }
      const originalRequest = error.config
      if (error.request.status === 400) {
        if (error.response.data) {
          if (error.response.data.detail.includes('credentials')) {
            await store.dispatch('auth/logout')
            TokenService.removeUser()
            localStorage.clear()
            window.location.href = "/login"
            router.go()
          } else {
            snackbarPlugin.showError(error.response.data.detail)
            return Promise.reject(error)
          }
        }
      }
      if (error.request.status === 422) {
        if (error.response.data.detail[0].loc[0] === 'cookie') {
          await store.dispatch('auth/logout')
          window.location.href = "/login"
          router.go()
        }
      }
      if (error.request.status === 401 && originalRequest.url !== '/login' && error.response) {
        if (error.config.url.includes('/refresh')) {
          // refresh token is expired / invalid
          await store.dispatch('auth/logout')
          this.$router.go()
          throw error
        }
        // Access Token was expired (On first Request)
        try {
          // call the refresh token path once or wait until the first one calling it is done
          await store.dispatch('auth/triggerRefresh')
          return axiosInstance(originalRequest)
        } catch (err) {
          if (err.response && err.response.data) {
            return Promise.reject(err.response.data)
          }
          return Promise.reject(error)
        }
      }
      if (error.response.data.detail && originalRequest.url !== '/login' && error.response) {
        if (!error.response.data.detail.includes('The asset does not exist')) {
        }
        return Promise.reject(error)
      }
      if (error.response.status === 403 && originalRequest.url !== '/login' && error.response) {
        snackbarPlugin.showError("You doesn't have enough privileges")
        return Promise.reject(error)
      } else {
        snackbarPlugin.showError(error.response.data.detail)
        return Promise.reject(error)
      }
    }
  )

  axiosInstance.interceptors.request.use(
    async(config) => {
      let token
      if (config.url.includes('/refresh')) {
        token = TokenService.getLocalRefreshToken()
      } else {
        token = TokenService.getLocalAccessToken()
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      snackbarPlugin.showError(error.response.data.detail)
      return Promise.reject(error)
    }
  )
}

export default setup
