import AuthService from '@/services/auth.service'
import UserService from '@/services/user.service'
import TokenService from '@/services/token.service'

const user = TokenService.getUser()
const profile = UserService.getLocalProfile()

const initialState = user
  ? { status: { loggedIn: true }, user: user, profile: profile, refreshTokenPromise: null }
  : { status: { loggedIn: false }, user: null, profile: null, refreshTokenPromise: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    setProfile({ commit }, profile) {
      return UserService.getProfile().then(
        profile => {
          commit('setProfile', profile)
          return Promise.resolve(profile)
        }
      )
    },
    login2FA({ commit }, data) {
      return AuthService.login2fa(data).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    ssoLogin({ commit }, data) {
      return AuthService.ssoAccessToken(data).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          console.log(error)
          return Promise.reject(error)
        }
      )
    },
    ssoLoginSub({ commit }) {
      commit('loginSuccess', user)
      return Promise.resolve(user)
    },
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('registerFailure')
          return Promise.reject(error)
        }
      )
    },
    triggerRefresh({ commit, state }) {
      // If this is the first time the refreshToken has been called, make a request
      // otherwise return the same promise to the caller
      if (!state.refreshTokenPromise) {
        const promise = AuthService.refreshToken()
        commit('refreshTokenPromise', promise)
        // Wait for the AuthService.refreshToken() to resolve. On success set the token and clear promise
        // Clear the promise on error as well.
        promise.then(
          response => {
            commit('refreshTokenPromise', null)
            commit('loginSuccess', response)
          },
          error => {  // eslint-disable-line
            commit('refreshTokenPromise', null)
          }
        )
      }
      return state.refreshTokenPromise
    }
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile
      UserService.setLocalProfile(profile)
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
    refreshTokenPromise(state, promise) {
      state.refreshTokenPromise = promise
    }
  }
}
