import axios from 'axios'

axios.defaults.withCredentials = true

const instance = axios.create({
  credentials: 'include',
  baseURL: process.env.VUE_APP_DOMAIN,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default instance
