// Vuetify Documentation https://vuetifyjs.com

import Vue from "vue"
import Vuetify from "vuetify/lib"
import { themePreset } from "@/themeConfig"

import fr from "vuetify/lib/locale/fr"
import en from "vuetify/lib/locale/en"

const locales = {
  en: en,
  fr: fr
}

const current = navigator.language.split("-")[0]

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales,
    current
  },
  theme: themePreset.theme,
  rtl: themePreset.rtl
})
