import axios from 'axios'

import TokenService from './token.service'
import snackbarPlugin from '@/plugins/snackbar'

const baseURL = process.env.VUE_APP_DOMAIN

function authAuthorization(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
}

axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    // handle error
    if (error.response) {
      // this.$store.dispatch('auth/logout')
      snackbarPlugin.showError(error.response.data.detail)
    }
    return error
  }
)

class AuthService {
  async login2fa(data) {
    const response = await axios.post(`${baseURL}/api/v1/login/check-pin`, data)
    if (response.data.access_token) {
      TokenService.setUser(response.data)
      if (response.data.pin_token !== 'null') {
        localStorage.pinToken = response.data.pin_token
      }
    }
    return response.data
  }

  async ssoLogin(provider) {
    const response = await axios.get(`${baseURL}/api/v1/login/${provider}`)
    return response.data
  }

  async ssoRegister(provider, registrationToken) {
    const response = await axios.get(
      `${baseURL}/api/v1/register/${provider}`, authAuthorization(registrationToken)
    )
    return response.data
  }

  async ssoAccessToken(data) {
    const response = await axios.post(`${baseURL}/api/v1/login/${data.provider}/access-token`, data)
    if (response.data) {
      TokenService.setUser(response.data)
      if (response.data.pin_token !== 'null') {
        localStorage.pinToken = response.data.pin_token
      }
    } else {
      window.location = "/"
      return response
    }
    return response
  }

  async ssoRegisterToken(provider, data) {
    const response = await axios.post(`${baseURL}/api/v1/register/${provider}/access-token`, data)
    if (response.data.access_token) {
      TokenService.setUser(response.data)
      if (response.data.pin_token !== 'null') {
        localStorage.pinToken = response.data.pin_token
      }
    }
    return response.data
  }

  async login({ username, password }) {
    const params = new URLSearchParams()

    params.append('username', username)
    params.append('password', password)
    if (localStorage.pinToken && localStorage.pinToken !== 'null') {
      params.append('pin_token', localStorage.pinToken)
    }

    const response = await axios.post(`${baseURL}/api/v1/login/access-token`, params)

    if (response.data.access_token) {
      TokenService.setUser(response.data)
    }
    return response.data
  }

  async refreshToken() {
    const refreshToken = TokenService.getLocalRefreshToken()
    const response = await axios.post(
      `${baseURL}/api/v1/login/refresh-token`, {}, authAuthorization(refreshToken)
    )
    // save the tokens
    if (response.data.access_token) {
      TokenService.setUser(response.data)
    }
    return response.data
  }

  async passwordReset(email) {
    const response = await axios.post(`${baseURL}/api/v1/password-recovery/`, email)
    return response
  }

  async confirmPasswordReset(newPassword, token) {
    const response = await axios.post(
      `${baseURL}/api/v1/reset-password/`, { new_password: newPassword }, authAuthorization(token)
    )
    return response
  }

  logout() {
    try {
      axios.delete(`${baseURL}/api/v1/login/tokens`,
      {
        headers: {
          Authorization: `Bearer ${TokenService.getLocalRefreshToken()}`
        }
      })
    } catch (error) {
      // skip
    } finally {
      TokenService.removeUser()
    }
  }

  logoutAllSession() {
    axios.delete(`${baseURL}/api/v1/login/all-tokens`,
    {
      headers: {
        Authorization: `Bearer ${TokenService.getLocalRefreshToken()}`
      }
    })
    TokenService.removeUser()
  }

  async checkCommunicationToken(token) {
    const response = await axios.get(
      `${baseURL}/api/v1/communications/transfer/pin`,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    if (response.status !== 200) {
      throw response
    }
    return response.data
  }

  async validateCommunicationPin(data, token) {
    const response = await axios.post(
      `${baseURL}/api/v1/communications/transfer/check-pin`,
      data,
      { headers: { Authorization: `Bearer ${token}` } }
    )
    if (response.status !== 200) {
      throw response
    }
    return response.data
  }
}

export default new AuthService()
