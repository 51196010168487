<template>
  <!-- box-shadow: 0 2px 4px 0 hsl(198deg 45% 10% / 12%) -->
  <v-card
    class="mx-auto"
    :class="{ 'pa-4': padding, cardClass }"
    :color="$vuetify.theme.dark && color == 'white' ? 'dark' : color"
    max-width="100%"
    flat
  >
    <v-skeleton-loader
      height="100%"
      width="100%"
      type="card"
      :loading="getThemeMode.isLoading"
    >
      <div :class="noPadding ? '' : 'pa-5'">
        <div class="d-flex align-start" :class="noPadding ? 'pt-5 pl-5' : ''">
          <span class="lstick" :class="colorBorder" />
          <v-icon
            v-if="icon"
            size="30"
            class="mr-3 pl-2"
          >
            mdi-{{ icon }}
          </v-icon>
          <h3 class="title font-weight-regular">
            {{ title }}
          </h3>
          <v-spacer />
          <slot name="action" />
        </div>
        <slot />
      </div>
    </v-skeleton-loader>
  </v-card>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  export default {
    name: 'Card',
    props: {
      icon: {
        type: String,
        default: '',
      },
      color: {
        type: String,
        default: 'white',
      },
      cardClass: {
        type: String,
        default: '',
      },
      padding: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
      colorBorder: {
        type: String,
        default: 'primary',
      },
      noPadding: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapGetters(['getThemeMode']),
    },
  }
</script>

<style lang="scss" scoped>
.lstick {
  width: 4px;
  height: 30px;
  margin-left: -20px;
  margin-right: 18px;
  position: absolute;
}
</style>
