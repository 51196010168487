/*
 * ============================
 * File: Octavia.kit.js
 * Project: Octavia-Admin
 * File Created: Monday, 13th April 2020 6:18:44 pm
 * Author:UILIB
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */

import VuePerfectScrollbar from 'vue-perfect-scrollbar'

// theme Main scss
import '@/assets/scss/octavia-design-system/octavia-design-system.scss'

// plugins menu

// javascript import for when you're importing the css directly in your javascript
import 'vue-navigation-bar/dist/vue-navigation-bar.css'
// import the library
import VueNavigationBar from 'vue-navigation-bar'

// axios
import axios from 'axios'
import VueAxios from 'vue-axios'

import VueMeta from 'vue-meta'
// end plugins

export default {
  install (Vue) {
    Vue.use(VueMeta)

    Vue.use(VueAxios, axios)
    Vue.component('vue-navigation-bar', VueNavigationBar)
    // Vue.component(
    //   'HorizontalBar',
    //   // The `import` function returns a Promise.

    //   () => import('../layouts/horizontal/Index')
    // )
    // Vue.component(
    //   'VerticalSidebar',
    //   // The `import` function returns a Promise.

    //   () => import('../layouts/vertical/Index')
    // )

    Vue.component(
      'VerticalWhiteSidebar',
      // The `import` function returns a Promise.

      () => import('../layouts/Index'),
    )

    // Vue.component(
    //   'VerticalCompactSidebar',
    //   // The `import` function returns a Promise.

    //   () => import('../layouts/vertical-compact-layout/Index')
    // )
    // Vue.component(
    //   'VerticalSaasLayout',
    //   // The `import` function returns a Promise.

    //   () => import('../layouts/vertical-saas-layout/Index')
    // )

    Vue.component('VuePerfectScrollbar', VuePerfectScrollbar)
  },
}
