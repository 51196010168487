import message from '../store/'

const snackbarPlugin = {
  showSuccess: (content = '') => {
    message.dispatch('message/showSuccess', content)
  },
  showLoading: (content = '') => {
    message.dispatch('message/showLoading', content)
  },
  showError: (content = '') => {
    message.dispatch('message/showError', content)
  },
  install: (Vue, { store }) => {
    if (!message) {
      throw new Error('Please provide vuex store.')
    }
    Vue.prototype.$snackbar = snackbarPlugin
  },
}

export default snackbarPlugin
