class TokenService {
  getLocalRefreshToken() {
    const user = this.getUser()
    return user?.refresh_token
  }

  getLocalAccessToken() {
    const user = this.getUser()
    return user?.access_token
  }

  getUser() {
    const userRaw = localStorage.getItem('user')
    if (userRaw && userRaw !== 'undefined') {
      return JSON.parse(userRaw)
    }
    return null
  }

  setUser(user) {
    localStorage.setItem('user', JSON.stringify(user))
  }

  removeUser() {
    localStorage.removeItem('user')
    localStorage.removeItem('profile')
  }
}

export default new TokenService()
