const initialState = {
    message: '',
    color: '',
    timeout: '',
}

export const message = {
    namespaced: true,
    state: initialState,
    actions: {
        showError ({ commit }, message) {
            commit('showMessage', { message: message, color: 'danger', timeout: '5000' })
        },
        showSuccess ({ commit }, message) {
            commit('showMessage', { message: message, color: 'success', timeout: '5000' })
        },
        showLoading ({ commit }, message) {
            commit('showMessage', { message: message, color: 'primary', timeout: -1 })
        },
    },
    mutations: {
        showMessage (state, { message, color, timeout }) {
            state.message = message
            state.color = color
            state.timeout = timeout
        },
    },
}
