import Vuex from 'vuex'
import Vue from 'vue'
import themeConfig from './modules/theme.module'
import { auth } from './modules/auth.module'
import { message } from './modules/message.module'
import { initializeData } from './modules/initializeData.module.js'

// Load Vuex
Vue.use(Vuex)

// Create store
export default new Vuex.Store({
  modules: {
    themeConfig,
    auth,
    message,
    initializeData,
  },
})
