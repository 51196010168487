<template>
  <v-autocomplete
    v-model="selected"
    :loading="loading"
    :items="items"
    :search-input.sync="search"
    :item-text="itemText"
    :item-value="itemValue"
    return-object
    :placeholder="placeholder"
    :label="label"
    :rules="rules"
    :solo="!!styleExtra.solo"
    :flat="!!styleExtra.flat"
    :outlined="!!styleExtra.outlined"
    :background-color="styleExtra['background-color']"
    :style="baseStyle"
    :dense="!!styleExtra.dense"
    clearable
    single-line
    :hide-details="styleExtra['hide-details']"
    class="shrink"
    :disabled="isDisabled"
  />
</template>

<script>
import _ from 'lodash'

export default {
  name: 'AsyncSearchSelect',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    itemText: {
      type: String,
      required: true
    },
    itemValue: {
      type: String,
      default: "id"
    },
    itemsUpdate: {
      type: Function,
      required: true
    },
    rules: {
      type: Array,
      default: () => []
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    baseStyle: {
      type: String,
      default: 'max-width:237px'
    },
    styleExtra: {
      type: Object,
      default: () => ({
        solo: true, flat: true, dense: true, 'background-color': "grey", 'hide-details': true
      })
    }
  },
  data: function() {
    return {
      search: '',
      previousSearch: '',
      debounceSearch: null,
      loading: !this.isDisabled && !this.value
    }
  },
  watch: {
    search(value) {
      if (!this.selected) {
        this.searchAfterWait()
      } else {
        if (value !== this.selected[this.itemText]) {
          this.$emit('input', null)
          this.searchAfterWait()
        }
      }
    },
    items(value) {
      this.loading = false
    }
  },
  methods: {
    searchAfterWait() {
      if (!this.debounceSearch) {
        this.debounceSearch = _.debounce(this.fetch, 500)
      }
      this.debounceSearch() // Call debounce
    },

    fetch() {
      if (this.search !== this.previousSearch) {
        this.loading = true
        this.previousSearch = this.search
        this.itemsUpdate(this.search || '')
      }
    }
  },
  computed: {
    selected: {
      get() {
        return this.value
      },
      set(value) {
        if (value) {
          this.$emit('input', value)
        }
      }
    }
  }
}
</script>
