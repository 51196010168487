export default {
  communication: {
    enterPinCode: "Saisissez le code à 6 chiffres qui a été envoyé à votre adresse courriel",
    oneTimeDownloadLink: "Ce lien de téléchargement est à usage unique. Si vous rencontrez un problème ou avez des questions, veuillez contacter notre équipe."
  },
  messages: {
    downloadStarted: "Le téléchargement vient de commencer!",
    okGotIt: "Oui, j'ai compris!"
  },
  learnMore: "En Apprendre Plus",
  download: "Télécharger",
  fileName: "Nom du fichier",
  send: "Envoyer",
  twoFASecurity: "Vérification d'identité",
  validation: {
    fieldRequired: "Ce champ est requis"
  },
  docReady: "Votre document est prêt!",
  date: "Date",
  actions: "Actions"
}
