<template>
  <span class="pa-0  d-flex align-center">
    <svg v-if="position !== 1" style="margin-right: 0.1vh; color: #D1D5DB;" height="68" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
      <path d="M0 -2L20 40L0 82" vector-effect="non-scaling-stroke" stroke="currentcolor" stroke-linejoin="round"></path>
    </svg>
    <div class=" d-flex align-center px-4 py-2">
      <v-tooltip class="d-lg-none" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-card v-if="setState() !== 2" v-bind="attrs" v-on="on" outlined class="rounded-circle pa-2 d-lg-none">
            <v-icon
              :color="setState() === 0 ? '' : setState() === 1 ? 'primary lighten-2' : 'success'"
              size="24"
            >
              {{ icon ? icon : "mdi-progress-question" }}
            </v-icon>
          </v-card>
          <v-card v-else v-bind="attrs" v-on="on" flat color="success" class="rounded-circle pa-2 d-lg-none">
            <v-icon
              color="white"
              size="30"
            >
              {{ icon ? icon : "mdi-progress-question" }}
            </v-icon>
          </v-card>
        </template>
        <span>{{ text ? text : "N/A" }}</span>
      </v-tooltip>
      <v-card v-if="setState() !== 2" outlined class="rounded-circle pa-2 d-none d-lg-block">
        <v-icon
          :color="setState() === 0 ? '' : setState() === 1 ? 'primary lighten-2' : 'success'"
          size="30"
        >
          {{ icon ? icon : "mdi-progress-question" }}
        </v-icon>
      </v-card>
      <v-card v-if="setState() === 2" flat color="success" class="rounded-circle pa-2 d-none d-lg-block">
        <v-icon
          color="white"
          size="30"
        >
          mdi-check
        </v-icon>
      </v-card>
      <h6 style="font-size: 14px !important; color: #6B7280;" class="mb-0 ml-3 d-none d-lg-block">{{ text ? text : "N/A" }}</h6>
    </div>
  </span>
</template>

<script>
export default {
  props: ["icon", "text", "isCheck", "position"],
  methods: {
    setState() {
      if (this.isCheck === 7 && this.position === 7) {
        return 2
      } else {
        return this.isCheck === this.position ? 1 : (this.isCheck > this.position ? 2 : 0)
      }
    }
  }
}
</script>
