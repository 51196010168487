const initialState = {
    refresh: false,
}

export const initializeData = {
    namespaced: true,
    state: initialState,
    actions: {
        refreshData ({ commit }, date) {
            commit('isRefresh', date)
        },
    },
    mutations: {
        isRefresh (state, data) {
            state.refresh = data
        },
    },
}
