<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  metaInfo() {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Vumetric Portal',
      // all titles will be injected into this template
      titleTemplate: 'Vumetric - PTaaS - %s'
    }
  },
  created() {
    setTimeout(() => {
      if (localStorage.theme === 'true') {
        this.$vuetify.theme.dark = true
      }
      if (localStorage.theme === 'false') {
        this.$vuetify.theme.dark = false
      }
    })
    this.getRouterParams()
  },
  methods: {
    getRouterParams() {
      // console.log(this.$router.currentRoute.query)
    }
  }
}
</script>
<style lang="scss">
</style>
