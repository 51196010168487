import axios from 'axios'
import api from './api'
import { paginate } from '@/services/utils'

const baseURL = process.env.VUE_APP_DOMAIN

class UserService {
  async deleteUser(id) {
    const response = await api.delete(`/api/v1/users/${id}`)
    return response.data
  }

  async getUsers(page, perPage, search, filters) {
    const requestUrl = paginate('/api/v1/users/', page, perPage, search, filters)
    const response = await api.get(requestUrl)
    return response.data
  }

  // Get my profile locally (localstorage)
  getLocalProfile() {
    const profileRaw = localStorage.getItem('profile')
    if (profileRaw && profileRaw !== 'undefined') {
      return JSON.parse(profileRaw)
    }
    return null
  }

  setLocalProfile(profile) {
    localStorage.setItem('profile', JSON.stringify(profile))
  }

  async inviteUser(data) {
    const response = await api.post('/api/v1/users/invite', data)
    return response.data
  }

  async ComfirmUser(token) {
    const response = await axios.get(`${baseURL}/api/v1/users/confirm`,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async ActivateUser(data, token) {
    const response = await api.post(`${baseURL}/api/v1/users/register`, data,
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    return response
  }

  async getProfile() {
    const response = await api.get('/api/v1/users/me')
    return response.data
  }

  async putMyProfile(data) {
    const response = await api.put('/api/v1/users/me', data)
    return response.data
  }

  async updateUser(id, data) {
    const response = await api.put(`/api/v1/users/${id}`, data)
    return response.data
  }

  async postUser(data) {
    const response = await api.post('/api/v1/users/', data)
    return response.data
  }

  async contactUs(data) {
    const response = await api.post('/api/v1/utils/contact-us/', data)
    return response.data
  }
}

export default new UserService()
