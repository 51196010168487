import _ from 'lodash'

export function paginate(resource, page, perPage, search, filters) {
  search = search || ''
  let queries = `search=${search}`
  filters = filters || {}
  for (const query in filters) {
    if (filters[query] !== null && filters[query] !== '') {
      queries += `&${query}=${filters[query]}`
    }
  }
  if (_.isInteger(page) && _.isInteger(perPage)) {
    return `${resource}?page=${page}&itemsPerPage=${perPage}&${queries}`
  }
  return `${resource}?${queries}`
}
