import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'

import i18n from "@/plugins/i18n"
import vuetify from './plugins/vuetify'
import './plugins'
import snackbarPlugin from '@/plugins/snackbar'
import octaviaKit from '@/plugins/octavia.kit'
import 'sweetalert2/dist/sweetalert2.min.css'
import Interceptors from './services/Interceptors'

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import VueApexCharts from 'vue-apexcharts'

Vue.component('apexchart', VueApexCharts)

Vue.use(octaviaKit)
Vue.use(VueSweetalert2)
Vue.use(snackbarPlugin, { store })
Interceptors(store)

Vue.config.productionTip = false
Vue.config.devtools = false

Sentry.init({
  Vue,
  dsn: "https://0093d7f80c294aa7adbdcaeaa4eca5a7@o72383.ingest.sentry.io/4504752702947328",
  integrations: [
    new Sentry.Replay(),
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "app.vumetric.com", /^\//]
    })
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0
});

new Vue({
  i18n,
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
