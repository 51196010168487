<template>
  <v-snackbar
    v-model="show"
    :color="color"
    elevation="24"
    :timeout="timeout"
    bottom
    transition="scroll-x-reverse-transition"
  >
    <v-progress-circular
      v-if="color === 'primary'"
      indeterminate
      class="mr-3"
    />
    <v-icon v-else class="pr-2" dark>
      {{ color == "danger" ? "mdi-alert" : "mdi-check" }}
    </v-icon>
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn
        v-bind="attrs"
        color="white"
        text
        @click.native="show = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  export default {
    data () {
      return {
        show: false,
        message: '',
        color: '',
        timeout: -1,
      }
    },
    created () {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'message/showMessage') {
          this.message = state.message.message
          this.color = state.message.color
          this.timeout = state.message.timeout
          this.show = true
        }
      })
    },
  }
</script>
