<template>
  <v-container>
    <v-row class="justify-center">
      <div :class="level === 1 ? 'baseRiskLvlOnCritical d-flex' : 'baseRiskLvl d-flex'">
        <div v-if="level <= 4" class="overBaseRiskLvl" :class="level <= 4 ? 'lowGreen' : 'transparent'" />
        <div class="overBaseRiskLvlSeparator" />
        <div v-if="level <= 3" class="overBaseRiskLvl" :class="level <= 3 ? 'mediumYellow' : 'emptyLvl'" />
        <div v-if="level <= 3" class="overBaseRiskLvlSeparator" />
        <div v-if="level <= 2" class="overBaseRiskLvl" :class="level <= 2 ? 'highOrange' : 'emptyLvl'" />
        <div v-if="level <= 2" class="overBaseRiskLvlSeparator" />
        <div v-if="level <= 1" class="overBaseRiskLvl" :class="level <= 1 ? 'criticalRisk' : 'emptyLvl'" />
      </div>
    </v-row>
    <v-row class="justify-center">
      <span>{{ getRiskByLang }}</span>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    risk: {
      type: String,
      required: true
    },
    lang: {
      type: String,
      required: true
    }
  },
  data: () => ({
    color: 'info',
    name: ''
  }),
  computed: {
    level() {
      if (this.risk === 'critical') {
        return 1
      }
      if (this.risk === 'high') {
        return 2
      }
      if (this.risk === 'moderate') {
        return 3
      }
      if (this.risk === 'low') {
        return 4
      }
    },
    getRiskByLang() {
      if (this.risk === 'critical') {
        if (this.lang === 'en') {
          return 'Critical'
        } else {
          return 'Critique'
        }
      }
      if (this.risk === 'high') {
        if (this.lang === 'en') {
          return 'High'
        } else {
          return 'Élevé'
        }
      }
      if (this.risk === 'moderate') {
        if (this.lang === 'en') {
          return 'Moderate'
        } else {
          return 'Modéré'
        }
      }
      if (this.risk === 'low') {
        if (this.lang === 'en') {
          return 'Low'
        } else {
          return 'Faible'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.baseRiskLvl{
  height: 21px;
  width: 74px;
  border: solid 2px #000000;
  background: #dfdfdf4b;
}
// force dark background to hide possible pixel shift on zoom
.baseRiskLvlOnCritical{
  height: 21px;
  width: 74px;
  border: solid 2px #000000;
  background: #000000;
}
.overBaseRiskLvl{
  width: 16px !important;
  height: 100% !important;
  //border-right: 2px solid #000000;
}
.overBaseRiskLvlSeparator{
  width: 2px !important;
  height: 100% !important;
  background: #000000;
}

.emptyLvl{
  background-color: transparent !important;
}

.criticalRisk{
  background: #ff0000;
}
.highOrange {
  background: orange;
}
.mediumYellow{
  background: yellow;
}
.lowGreen{
  background: #26fe1a;
}
</style>
